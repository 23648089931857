.icon-large {
  font-size: 169px;
}

.resume-page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* min-height: 83vh; */
  justify-content: center;
}

.image {
  max-width: 80vw;
  margin: 30px 0px 30px 0px;
}

.download-resume {
  color: #0000cc
}
