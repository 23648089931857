/* .service-cards-wrapper {
  display: grid;
  grid-template-columns: repeat(3, auto);
  column-gap: 16px;
  row-gap: 16px;
} */

.card-grid {
  display: grid;
  grid-template-columns: repeat(3, auto);
  column-gap: 8px;
  row-gap: 8px;
}

.card-wrapper {
  min-height: 200px;
  border-radius: 4px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-icon-title {
  display: flex;
  font-size: 18px;
  margin-top: 5px;
}

.card-text {
  padding: 5px;
}

.card-icon {
  margin-right: 5px;
}

.card-dot {
  border: 1px solid;
  border-radius: 50%;
  margin: 0 8px;
  min-width: 12px;
  min-height: 12px;
  cursor: pointer;
}

.card-dot-selected {
  background-color: #0000cc;
  border-radius: 50%;
  margin: 0 8px;
  min-width: 12px;
  min-height: 12px;
  cursor: pointer;
}

.dot-wrapper {
  justify-content: center;
  padding: 8px;
  display: flex;
}

@media (max-width: 750px) {
  .card-grid {
    grid-template-columns: repeat(1, auto);
    row-gap: 32px;
  }
}

.card-wrapper.dark {
  box-shadow: inset 0 0 0 3px #000080;
}

.card-wrapper.light {
  box-shadow: inset 0 0 0 3px #cacaca;
}
