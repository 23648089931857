.icon-large {
  font-size: 169px;
}

.projects-page-wrapper {
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-navigation {
  padding: 8px;
  display: block;
  justify-content: center;
  text-align: center;
}

.projects-navigation-title {
  font-size: 24px;
  text-decoration: underline;
  margin-top: 24px;
}

.project-navigation button {
  width: 130px;
}

.project-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-content h4 {
  font-size: 32px;
  text-transform: uppercase;
}

.project-content-description {
  margin: 16px;
  padding: 8px;
  font-size: 18px;
}

.project-content-description-title {
  margin-top: 32px;
}

.project-btn {
  border: 2px solid lightgrey;
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  margin: 16px;
  padding: 8px;
}

.project-btn.dark {
  color: white;
}

.project-btn:hover {
  background-color: lightgrey;
  color: black;
}

.project-btn:hover:disabled {
  background-color: transparent;
  cursor: default;
}

.project-btn:disabled {
  color: transparent;
}

.project-btn[active="true"] {
  background-color: #868e96;
  color: black;
}

.under-construction {
  min-height: calc(50vh + 175px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.search-form {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 16px 0;
}

@media (max-width: 450px) {
  .search-form {
    flex-direction: column;
    align-items: center;
  }
}

.search-form div {
  margin: 0 8px;
}

.art-card {
  box-shadow: 0 0 0 2px #cacaca;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 8px;
  margin: 8px 0;
}

.art-grid {
  padding: 50px;
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 16px;
  row-gap: 16px;
}

@media (max-width: 750px) {
  .art-grid {
    grid-template-columns: repeat(1, auto);
  }

  .project-navigation {
    display: block;
    margin: auto;
  }
}

.modal-art-grid {
  display: flex;
}

.modal-art-grid.dark {
  background-color: black;
}

.modal-text {
  padding: 16px;
}

.art-no-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
}
