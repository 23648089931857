.footer-wrapper {
  padding: 16px 0;
  display: flex;
  justify-content: center;
}

.footer-wrapper div {
  margin-right: 16px;
  font-size: 12px;
  text-transform: capitalize;
}

.ui.toggle.checkbox input:checked~label:before {
  background-color: #0000cc !important;
}

.ui.toggle.checkbox input:focus:checked~label:before {
  background-color: #0000cc !important;
}