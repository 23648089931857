/*
Application notes:

Color palatte:
#000080
#4d4dff
#00f
#262680
#0000cc

*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.scroll-to-bottom-button {
  position: fixed;
  right: 8px;
  bottom: 8px;
  display: none;
}

.scroll-to-bottom-button button {
  border: none;
  padding: 8px;
  border-radius: 4px;
}

@media (max-width: 450px) {
  .scroll-to-bottom-button {
    display: inherit;
  }
}

body.dark {
  background-color: black;
  color: #e9ecef;
  transition: background-color 200ms ease-in 50ms;
  transition: color 200ms ease-in 50ms;
}

body.light {
  color: black;
  transition: background-color 200ms ease-in 50ms;
  transition: color 200ms ease-in 50ms;
}
