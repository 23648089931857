.navbar {
  box-shadow: 0px 2px #cacaca;
  padding: 8px 0;
}

.navbar.dark {
  box-shadow: 0px 2px #0000cc;
}

.navbar-desktop {
  min-width: 100%;
}

.navbar-mobile {
  display: none;
}

@media (max-width: 750px) {
  .navbar-mobile {
    display: flex;
    width: 100%;
  }

  .navbar-desktop {
    display: none;
  }
}

.navbar-links-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%
}

.navbar-links-wrapper.dark {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%
}

.navlink {
  padding-left: 24px;
}

.hamburger-wrapper {
  min-width: 100%;
  display: flex;
  flex-direction: column;
}

.hamburger-menu-wrapper {
  min-width: 100%;
  display: grid;
  grid-template-columns: repeat(1, auto);
}

.navlink-mobile {
  min-width: 100%;
  border: 1px solid #cacaca;
  margin: 8px 0;
  padding: 8px 0;
  border-radius: 4px;
  text-align: center;
}

.nav-links-wrapper {
  display: flex;
  justify-content: flex-end;
}

.navbar-icon {
  display: flex;
  align-items: center;
}

.turn-stone-label {
  margin-left: 12px;
}

.turn-stone {
  border-radius: 4px;
  max-height: 36px;
  cursor: pointer;
  border: none;
}

.turn-stone-img {
  border-radius: 4px;
  height: 36px;
  padding: 4px;
  cursor: pointer;
}

.turn-stone.dark {
  animation: flipDark .5s ease forwards;
}

@keyframes flipDark {
  from {
    transform: rotateX(180deg);
    background-color: black;
  }
  to {
    transform: rotateX(360deg);
    background-color: white;
  }
}

.turn-stone.light {
  animation: flipLight .5s ease forwards;
}

@keyframes flipLight {
  from {
    transform: rotateX(0deg);
    background-color: white;
  }
  to {
    transform: rotateX(180deg);
    background-color: black;
  }
}
