.projects-portal-wrapper {
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 32px 0;
}

.projects-headline {
  max-width: 400px;
  margin-bottom: 16px;
  text-align: justify;
}

.checkbox-wrapper {
  margin-bottom: 8px;
  cursor: pointer;
}

.checkbox1 {
  cursor: pointer;
}

.email {
  text-align: center;
}

.email-nav-btn.light {
  color: lightgrey;
}

.email-nav-btn.light:hover {
  color: black;
}
